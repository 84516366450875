import type { ComponentType } from "react";
import type { RenderFunc } from "./ErrorBoundary";
import { ErrorBoundary } from "./ErrorBoundary";

export const withErrorBoundary = <Props extends object = object>(
  WrappedComponent: ComponentType<Props>,
  renderFunc: RenderFunc,
  options = { handleNotFoundError: false },
) => {
  const component = (props: Props) => (
    <ErrorBoundary
      renderFunc={renderFunc}
      handleNotFoundError={options.handleNotFoundError}
    >
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );

  component.displayName = `withErrorBoundary(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return component;
};
