import { useEffect, useRef, useState } from "react";
import { ChatList } from "@/react/components/SettingsApp/CommunityInbox/Sidebar/ChatList";
import { CommunityInboxChatSearch } from "@/react/components/SettingsApp/CommunityInbox/Sidebar/CommunityInboxChatSearch";
import { Header } from "@/react/components/SettingsApp/CommunityInbox/Sidebar/Header";
import {
  ALL_AGENTS_INITIAL_STATE,
  CHAT_SORT_VALUES,
  CHAT_TYPES,
} from "@/react/components/SettingsApp/CommunityInbox/Sidebar/constants";
import { AiInboxTitle } from "@circle-react/components/SettingsApp/CommunityInbox/Sidebar/AiInboxTitle";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import type {
  Agent,
  ChatSortType,
  ChatType,
} from "@circle-react/types/CommunityBot/ChatSort";

export const CommunityInboxLinks = () => {
  const { totalUnreadPausedAiChatsCount = 0 } = useCommunityInboxStore();
  const [activeSort, setActiveSort] = useState<ChatSortType>(
    CHAT_SORT_VALUES.LATEST,
  );
  const [activeAgent, setActiveAgent] = useState<Agent>(
    ALL_AGENTS_INITIAL_STATE,
  );
  const hasSetInitialState = useRef(false);

  const [activeChatType, setActiveChatType] = useState<ChatType>(
    CHAT_TYPES.ALL,
  );
  const { adminChats: chats } = useCommunityInboxStore();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false);
  useEffect(() => {
    if (!hasSetInitialState.current && totalUnreadPausedAiChatsCount > 0) {
      setActiveChatType(CHAT_TYPES.AI_PAUSED);
      hasSetInitialState.current = true;
    }
  }, [totalUnreadPausedAiChatsCount]);

  const shouldShowHeader =
    !isSearchBarVisible &&
    (activeChatType !== CHAT_TYPES.ALL || Boolean(chats.length));
  return (
    <div className="block h-full">
      <AiInboxTitle />
      {shouldShowHeader && (
        <Header
          activeSort={activeSort}
          setActiveSort={setActiveSort}
          activeChatType={activeChatType}
          setActiveChatType={setActiveChatType}
          setActiveAgent={setActiveAgent}
          activeAgent={activeAgent}
          setIsSearchBarVisible={setIsSearchBarVisible}
        />
      )}
      {isSearchBarVisible && (
        <CommunityInboxChatSearch
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setIsSearchBarVisible={setIsSearchBarVisible}
        />
      )}
      {!searchQuery && (
        <ChatList
          activeSort={activeSort}
          activeChatType={activeChatType}
          activeAgent={activeAgent}
        />
      )}
    </div>
  );
};
