import { t } from "@/i18n-js/instance";
import { ErrorTemplate } from "./ErrorTemplate";
import type { ErrorTemplateProps } from "./ErrorTemplateComponent";

export interface SomethingWentWrongProps
  extends Omit<ErrorTemplateProps, "title" | "errorCode"> {}

/**
 * 500 Error Page
 */
export const SomethingWentWrong = (props: SomethingWentWrongProps) => (
  <ErrorTemplate
    title={t("error_page.something_went_wrong")}
    errorCode={500}
    {...props}
  />
);
